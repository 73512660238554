.modules {
  float: right;

}

.module {

}

.module--padded {
  padding: $sidePaddingMobile;
  @include mq($FULLFAT) {
    padding: $sidePadding;
  }
}

.module--html {
  @extend .module--padded;
  iframe {
    width: 100%;
  }
}
