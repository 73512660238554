a {
  color: $primary;
  text-decoration: none;
}

.standfirst {
  font-family: $baseFontFamily;
  line-height: 1.4;
  color: $goldDark;
  font-size: 1.3em;
  margin-top: 0;
}


.box a {
  color: $goldDark;
  &:hover {
    text-decoration: underline;
  }
}

.box {
 h2 {
   font-size: 1.3em;
   font-weight: 500;
   margin-top: 2em;
   border-bottom: 1px solid $hairline;
   padding-bottom:0.5em;
 }
}

.block--text table {
  td {
    padding: 0.5em 0;
  }
  td + td {
    padding-left: 2em;
  }
  tr + tr {
    border-top: 1px solid rgba(black, 0.05);
  }
}
