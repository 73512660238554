.primary-nav-action {
	position: absolute;
	right: $sidePaddingMobile;
	top:6.5em;
	background-image: url(../img/burger.svg);
	background-repeat: no-repeat;
	background-position: center;
	z-index: 999;
	width: 40px;
	height: 40px;
	text-indent: -9999px;
	transition: right 0.5s ease;
	@include mq($FULLFAT) {
		display: none;
	}
}

.primary-nav-action--active {
	right: $sideMenuWidth + $sidePaddingMobile;
}

.primary-nav {
	ul {
		@extend .unstyled-list;
	}
	li{
		position: relative;
	}
	li.has-children span {
		position: absolute;
		content: '';
		background-image: url('../img/primary-nav-right-arrow.svg');
		background-position: center;
		background-repeat: no-repeat;
		right: 0;
		top:0;
		height: 46px;
		width: 30px;
		cursor: crosshair;
		z-index: -1;
	}
	li + li {
		border-top: 1px solid rgba($primary,0.4);
	}

	a {
		position: relative;
		display: block;
		padding: 0.7em 0.8em;
		@extend .easynow;
		&:hover {
			background-color: rgba($primary,0.1);
		}
	}

	li.active a {
		background-color: #8A614A;
	}
}

.primary-nav--desktop {
	width: 23%;
	float: left;
	font-size: 1.05em;
	ul ul {
		display: none;
	}

	@media screen and (max-width: $FULLFAT) {
		display: none;
	}
}

.primary-nav--mobile {
	display: none;
	li.active.has-children > span {
		-ms-transform: rotate(90deg); /* IE 9 */
  	-webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
  	transform: rotate(90deg);
	}
	.has-children li.active a {
		background-color: $primary;
		color: $secondary;
	}
	ul ul {
		display: none;
	}
	li.active ul {
		display: block;
	}
	ul ul li a:after {
		display: none;
	}

	@media screen and (max-width: $FULLFAT) {
		display: block;
		width: $sideMenuWidth;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
	}

}

.britains-finest {
	margin-top: 1em;
	text-align: left;
	&:hover {
		background-color: transparent !important;
	}
}