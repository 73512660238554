html {
  box-sizing: border-box;
  height: 100%;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  height: 100%;
	background-color: #27131E;
	line-height: $baseLineHeight;
	font-size: $baseFontSize;
	font-family: $baseFontFamily;
	color: $baseFontColor;
	-webkit-font-smoothing: antialiased;
}
