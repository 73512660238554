.suites {

}

.suite {

}

.suite__title {
  font-family: $baseFontFamily;
  font-weight: normal;
  border-bottom: 1px solid $hairline;
  padding: 0.5em 0;
  cursor: pointer;
  &:before {
    display: block;
    float: left;
		content: '';
		background-image: url('../img/arrow--dark.svg');
		background-position: center;
		background-repeat: no-repeat;
		width: 30px;
    height: 30px;
  }
}

.suite__images {
  @extend .unstyled-list;
  @include row(12);
  @include clearfix();
  li {
    @include column(4);
  }
  a {
    display: inline-block;
  }
  img {
    width: 100%;
  }
}


.suite--active {
  .suite__title {
    &:before {
      -ms-transform: rotate(90deg); /* IE 9 */
    	-webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
    	transform: rotate(90deg);
    }
  }
}
