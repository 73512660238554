$boxSidePadding: 3rem;

.box {
  @extend .clearfix;
  position: relative;
  background-color: $boxBG;
  color: $secondary;
  line-height: 1.8;
}

// Elements

.box__title {
  color: $secondary;
  font-weight: normal;
  font-size: 1.6em;
  padding: 1.4rem $sidePaddingMobile;
  margin: 0;
  border-bottom: 1px solid #E6CEAE;
  @include mq($FULLFAT) {
    padding: 1.4rem $sidePadding;
  }
}

.box__main {
  @extend .clearfix;

}

.box__content {
  padding: $sidePaddingMobile;
  font-family: $secondaryFontFamily;
  @include mq($FULLFAT) {
    padding: $sidePadding;
  }
}

.box__modules {

}

// Modifiers


.box--has-modules {
  .box__content {
      @include mq($FULLFAT) {
        width: 65%;
        float: left;

      }
  }
  .box__modules {
    @include mq($FULLFAT) {
      width:35%;
      overflow: hidden;
      position: absolute;
      top:0;bottom:0; right:0;

      background-color: #f3eae0;
      border-left: 1px solid #E6CEAE;
    }
  }
}
