@mixin radius($topleft, $topright: false, $bottomright: false, $bottomleft: false) {
  @if $topright {
    -moz-border-radius: $topleft $topright $bottomright $bottomleft;
    -webkit-border-radius: $topleft $topright $bottomright $bottomleft;
    -ms-border-radius: $topleft $topright $bottomright $bottomleft;
    border-radius: $topleft $topright $bottomright $bottomleft;
  } @else {
    -moz-border-radius: $topleft;
    -webkit-border-radius: $topleft;
    -ms-border-radius: $topleft;
    border-radius: $topleft;
  }
}

@mixin box-sizing($type:"border-box") {
	box-sizing: 				#{$type};
	-webkit-box-sizing: #{$type};
	-moz-box-sizing: 		#{$type};
	-o-box-sizing: 			#{$type};
}

@mixin gradient($from, $to) {
  background-color: $from;
  background-image: -moz-linear-gradient($from, $to);
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($from), to($to));
  background-image: -webkit-linear-gradient($from, $to);
  background-image: -o-linear-gradient($from, $to);
}

@mixin text-shadow ($x: 1px, $y: 1px, $blur: 1px, $color: rgba(0,0,0,0.5)) {
	text-shadow: $x $y $blur $color;
}

@mixin box-shadow($top, $left, $blur, $spread, $color, $inset: false) {
  @if $inset == true {
    -webkit-box-shadow:inset $top $left $blur $spread $color;
    -moz-box-shadow:inset $top $left $blur $spread $color;
    box-shadow:inset $top $left $blur $spread $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $spread $color;
    -moz-box-shadow: $top $left $blur $spread $color;
    box-shadow: $top $left $blur $spread $color;
  }
}

@mixin breakpoint($point) {
 @media all and (min-width: $point) { @content; }
}

@mixin mq($point){
 @if($media-queries){
   @include breakpoint($point) {
     @content;
   }
 }
 @else if($media-queries == false){
   @content;
 }
}

@mixin hires() {
 	@media only screen and (min--moz-device-pixel-ratio: 2),
		only screen and (-o-min-device-pixel-ratio: 2/1),
		only screen and (-webkit-min-device-pixel-ratio: 2),
		only screen and (min-device-pixel-ratio: 2) {
		@content;
	}
}
