.toolbar {
  border-bottom:1px solid $primary;
  overflow: hidden;
  @include mq($FULLFAT) {
    font-size: 1.1em;
    margin-top: 1em;
    border-bottom: 0;
    float: right;
  }
  ul {
    @extend .unstyled-list;
  }
  li {
    display: inline;
  }
  li + li:before {
    content: '•';
    display: inline-block;
    color: $primary;
  }
  li.hidden + li:not(.hidden):before {
    display: none;
  }
  li:last-of-type:before {
    display: none;
  }
  a {
    display: inline-block;
    padding: 0.8em 0.8em;
  }
  .button--book {
    float: right;
    border-width: 0px 0px 0px 1px;
    padding-left: 1em;
    padding-right: 1em;
    margin-bottom: 0;
    @include mq($FULLFAT) {
      margin-left: 1.5em;
      border-width: 1px;
      padding-left: 3em;
      padding-right: 3em;
    }
  }
}

// ELEMENTS

.toolbar__inner {
  @include mq($FULLFAT) {

  }
}

// MODIFIERS
