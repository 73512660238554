.button {
  display: inline-block;
  padding: 0.6em 1.2em;
  margin-bottom: 0.2em;
  margin-right: 0.5em;
}

.button + .button {

}

// MODIFIERS

.button--full {
  width: 100%;
}

.button--extended {
  padding-left: 3em !important;
  padding-right: 3em !important;
}

.button--book {
  @include box-shadow(0,0,0,10px,rgba(black,0.1),true);
  font-family: $baseFontFamily;
  font-size: 1.1rem;
  color: $white !important;
  background-color: $book;
  border-color: $primary;
  border-width: 1px;
  border-style: solid;
  margin-right: 0;
  &:hover {
    text-decoration: none !important;
    background-color: darken($book,5);
  }
}

.button--download {
  border: 1px solid $goldDark;
  font-family: $baseFontFamily;
  &:hover {
    text-decoration: none !important;
    background-color: rgba($goldDark,0.1);
  }
  &:before {
    content: '\f105';
    font-family: 'ionicons';
    margin-right: 0.5em;
  }
}
