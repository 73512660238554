.footer {
  @extend .clearfix;
  font-family: $secondaryFontFamily;
  border-top: rgba(white,0.2) solid 1px;
  color: #BDAEA7;
  padding: 1em 0;
  @include mq($FULLFAT) {
    padding: 3em 0;
  }
}

.footer__inner {
  @include row(12);
}

.footer__section {
  @include column(3);
  font-size: 0.9em;
  h3 {
    color: #634E4B;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1em;
  }
  ul {
    color: rgba($primary,0.6);
    padding-left: 1.2em;
  }
  a {
    color: rgba($primary,0.5);
  }
  p {
    float: left;
    margin-top: 0;
  }
  .contact-details {
    @extend .clearfix;
    @include mq(768px) {
      @include row(6);
    }
  }
  .adr {
    @include mq(768px) {
      @include column(2,6);
    }
  }
  .numbers {
    margin-top: 1em;
    @include mq(768px) {
      margin-top: 0;
      @include column(4,6);
    }
    font-size: 1.5em;
    line-height: 1.4em;
  }
}
.footer__section--extended {
  @include column(12);
  background-image: url(../img/q.svg);
  background-repeat: no-repeat;
  background-position: right;
  @include mq($FULLFAT) {
    @include column(6);


  }
}
