// Typefaces
$baseFontSize: 1em;
$baseLineHeight: 1.5em;
$baseFontFamily: Georgia, Times, "Times New Roman", serif;
$secondaryFontFamily: "Helvetica Neue", Helvetica, Arial, sans-serif;

// General variables
$sideMenuWidth: 15rem;

// Turn those sexy media queries on
$media-queries: true !default;
$FULLFAT: 950px;

// Pad tool
$maxWidth: 1450px;
$sidePaddingMobile: 1.5rem;
$sidePadding: 2rem;

//Grays
$black:                 #000;
$grayDarker:            #222;
$grayDark:              #333;
$gray:                  #555;
$grayLight:             #999;
$grayLighter:           #ccc;
$grayLightest:          #f7f7f7;
$white:                 #fff;
$hairline:           	#dadada;

//Colors

$gold: #F7C889;
$goldDark: #905F1A;
$purple: #361226;
$green: #458567;

// Color aliases

$hairline: #E6CEAE;
$primary: $gold;
$secondary: $purple;
$book: $green;
$boxBG: #EDDFCC;
$tabsBG: #DAC7AD;

// Defaults
$baseFontColor: #1d1d1d;
$canvas: $secondary;
