%valign {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.pad {
	max-width: $maxWidth;
	margin: 0 auto;
	padding: 0 $sidePadding;
}

// For use within the mq mixin
@mixin pad() {
	max-width: $maxWidth;
	margin: 0 auto;
	padding: 0 $sidePadding;
}

.clearfix {
	*zoom:1;
  &:before,
  &:after {
      content:"";
      display:table;
  }
  &:after {
      clear:both;
  }
}

// For use within the mq mixin
@mixin clearfix() {
  *zoom:1;

  &:before,
  &:after {
      content:"";
      display:table;
  }
  &:after {
      clear:both;
  }
}

.unstyled-list {
    list-style: none;
    margin: 0;
		padding: 0;
}

.hidden {
	display: none !important;
}

.hidden-on-mobile {
	@media screen and (max-width: $FULLFAT) {
		display: none !important;
	}
}

.hidden-on-desktop {
	@media screen and (min-width: $FULLFAT) {
		display: none !important;
	}
}

.easynow{
	transition: all 0.5s ease;
}

.border {
  position: absolute;
  z-index: 999;
  top:0;right:0px;bottom:0px;left:0px;
  border: 10px solid rgba($primary, 0.5);
}

.border--half {
	border: 5px solid rgba($primary, 0.5);
}
