.tabs {
  @extend .unstyled-list;
  background-color: $tabsBG;
  display:none;
  @include mq($FULLFAT) {
    display: block;
  }
  li {
    display: table-cell;
    vertical-align: middle;
    border-bottom: 1px solid rgba(black,0.05);
  }
  a {
    color: $secondary;
    font-size: 1.1em;
    display: inline-block;
    line-height: 1.2;
    padding: 0.8em 1.2em;
    border-right: 1px solid rgba(black,0.1);
    @extend .easynow;
  }
  a:hover {
    background-color: rgba(white,0.15);
    text-decoration: none;
  }
  li.active {
    background-color: $boxBG;
    @include box-shadow(2px, 0, 0px, 0, rgba(black,0.05));
    border-bottom:none;
    a {
      border-right: none;
    }
    a:hover {
      background-color: $boxBG;
    }
  }

}
