.blocks{

}

.block {
  position: relative;
  img {
    width: 100%;
  }
}

.block--right {
  @include mq($FULLFAT) {
    float:right;
    margin-left: 2em;
  }
}

.block--left {
  @include mq($FULLFAT) {
    float:left;
    margin-right: 2em;
  }
}

.block--standfirst {

}

.block--pdfs {
  margin: 2em 0;
}

.block--images {
  line-height: 0;
  margin-top: 1em;
 &.block--right, &.block--left {
   @include mq($FULLFAT) {
     width: 40%;
   }
 }
}
