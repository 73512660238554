.offers {

}

.offer {
  @extend .clearfix;
  position: relative;
  border: 1px solid $hairline;
  background-color: rgba($hairline,0.5);
  padding: 2em;
}

.offer + .offer {
  margin-top: 1em;
}

.offer__image {

  margin: 0 auto;
  margin-bottom: 1em;
  @include mq(750px) {
    width: 30%;
    float: left;
    margin-bottom: 0;
  }
  img {
    max-width: 280px;
    width: 100%;
    margin: 0 auto;
  }
}

.offer__title {
  font-size: 1.3em;
  margin-top: 0 !important;
}

.offer__text {
  @include mq(750px) {
    width: 65%;
    float: right;
    position: relative;
  }
}

.offer__terms {
  font-size: 0.8em;
}

.offer__book {
  margin-top: 1em;
}

.offer__expire {
  @include mq($FULLFAT) {
    position: absolute;
    top:0;
    right:0;
  }

}
