.wrap {
  @extend .clearfix;
  background-color: #27131E;
  min-width: 100%;
  min-height: 100%;
  position: relative;
  top: 0;
  bottom: 100%;
  right: 0;
  z-index: 1;
  transition: right 0.5s ease;
}

.wrap--active {
  @media screen and (max-width: $FULLFAT) {
    right: $sideMenuWidth;
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.5);
  }
}
