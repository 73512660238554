.logo {
  margin-left: 0.5em;
  width: 200px;
  @include mq($FULLFAT) {
    margin-left: 0;
  }
  img {
    width: 100%;
  }
}
